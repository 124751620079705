import React from "react";
import {navigate} from 'gatsby';

const GameCard = ({ children, navigationurl }) => {
    const navigationHandler = (url) => {
        navigate(url);
    }

    return (
        <div className="cardify-content-card" onClick={() => navigationHandler(navigationurl)}>
            {children}
        </div>
    )
};

export default GameCard;