import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/layout";
import GameCard from "../components/GameCard";
import OpenPoetry from "react-openpoetry";
import SEO from "../components/seo";
import 'react-openpoetry/dist/index.css';

const MainPage = props => {
    return (
        <>
            <SEO title="Játékok" />
            <h1>Játékok</h1>

            <div className="content-wrapper">
                <div className="header-wrapper">
                    <div className="cardify-content-wrapper">
                        <GameCard navigationurl="/jatekok/openpoetry">
                            <h5 className="main-content-card-title"><i className="fas fa-file-signature highlight-upcolor"></i> OpenPoetry</h5>
                            <p className="cardify-content-alt">Tanulj verseket egyszerűen</p>

                            <p className="main-content-card-city"><i className="fas fa-tags highlight-upcolor"></i> Ingyenes</p>
                        </GameCard>
                    </div>
                </div>
            </div>
        </>
    )
};

const RouteSubPage = props => {
    return (
        <>
            <SEO title="Verstanulás egyszerűen" />

            <OpenPoetry 
                name="Verstanulás egyszerűen" 
                slogan="Jegyezd meg a verset azáltal, hogy többször leírod." 
                heading1="Másold ki, és illeszd be a verset, amelyet gyakorolni szeretnél." 
                helper="Ha nincs tagolva, próbáld Te tagolni a jobb eredmény érdekében."
                nextButton="Játékra fel!"
                heading2="Próbáld megjegyezni, és kezdd el gépelni."
                hintText="Segítség"
                hintButton="Segítség mutatása/elrejtése"
                showHintOnStart={true}
            />
        </>
    )
}

const Jatekok = () => (
    <Layout>
        <Router>
            <MainPage path="/jatekok/" />
            <RouteSubPage path="/jatekok/:id" />
        </Router>
    </Layout>
);

export default Jatekok;